import React from "react";
import NavBar from "../components/navbar/NavBar";
import RetailComponent from "../components/retail/RetailComponent";
import FooterComponent from "../components/footer/FooterComponent";

function RetailPage() {
	return (
		<>
			<NavBar />
			<RetailComponent />
			<FooterComponent />
		</>
	);
}

export default RetailPage;
