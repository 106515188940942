import React from "react";
import { Link } from "react-router-dom";
import "./HeroStyles.css";

function hero() {
	return (
		<div className="hero">
			<div className="container">
				<div className="content">
					{/* <h1>Levin's Drugs</h1> */}
					<h1 className="blue">A PHARMACY</h1>
					<h1>That you can trust</h1>
					<div>
						<button>
							<Link to="/contact">;Learn More</Link>
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default hero;
