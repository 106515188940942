import React from "react";
import NavBar from "../components/navbar/NavBar";
import CompoundingComponent from "../components/compounding/CompoundingComponent";
import FooterComponent from "../components/footer/FooterComponent";

export default function CompoundPage() {
	return (
		<>
			<NavBar />
			<CompoundingComponent />
			<FooterComponent />
		</>
	);
}
