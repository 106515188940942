import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import RetailPage from "../src/pages/RetailPage";
import VaccinePage from "../src/pages/VaccinePage";
import CompoundPage from "../src/pages/CompoundPage";
import ContactPage from "../src/pages/ContactPage";
import TermsPage from "./pages/TermsPage";
import StoreLocationPage from "./pages/StoreLocationPage";
import PolicyPage from "./pages/PolicyPage";

ReactDOM.render(
	<BrowserRouter>
		<Routes>
			<Route path="/" element={<App />} />
			<Route path="/retail" element={<RetailPage />} />
			<Route path="/vaccine" element={<VaccinePage />} />
			<Route path="/compound" element={<CompoundPage />} />
			<Route path="/contact" element={<ContactPage />} />
			<Route path="/terms" element={<TermsPage />} />
			<Route path="/location" element={<StoreLocationPage />} />
			<Route path="/policy" element={<PolicyPage />} />
		</Routes>
	</BrowserRouter>,
	document.getElementById("root")
);
