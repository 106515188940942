import React from "react";
import "./RetailStyles.css";

function RetailComponent() {
	return (
		<div className="retail">
			<div className="content">
				<h2>
					<span>Retail Department </span>
				</h2>
				<p>
					Our Goal is to provide our patients with the highest quality and most
					comprehensive care. We assure that each patient receives individual,
					personal attention through close collaboration between our staff,
					physicians, patients and their families.
				</p>
			</div>
		</div>
	);
}

export default RetailComponent;
