import React from "react";
import Hero from "./components/hero/Hero";
import NavBar from "./components/navbar/NavBar";
import VaccineComponent from "./components/vaccine/VaccineComponent";
import CompoundingComponent from "./components/compounding/CompoundingComponent";
import RetailComponent from "./components/retail/RetailComponent";
import FooterComponent from "./components/footer/FooterComponent";

function App() {
	return (
		<>
			<NavBar />
			<Hero />
			<RetailComponent />
			<VaccineComponent />
			<CompoundingComponent />
			<FooterComponent />
		</>
	);
}

export default App;
