import React, { useState } from "react";
import { Link } from "react-router-dom";

// import Logo from "../../assets/Logo-transperent.png";
import { FaBars, FaTimes } from "react-icons/fa";
import "./NavbarStyles.css";

function NavBar() {
	const [nav, setNav] = useState(false);

	const handleNav = () => {
		setNav(!nav);
	};

	return (
		<div name="top" className="navbar">
			<div className="container">
				<div className="logo">
					{/* <img src={Logo} alt="Logo" className="icon" /> */}
					<h1>
						<span>Levins</span> Drugs
					</h1>
				</div>

				<ul className={nav ? "nav-menu active" : "nav-menu"}>
					<li>
						<Link to="/">Home</Link>
					</li>
					<li>
						<Link to="/retail">Retail</Link>
					</li>
					<li>
						<Link to="/vaccine">Vaccination</Link>
					</li>
					<li>
						<Link to="/compound">Compounding</Link>
					</li>
					<li>
						<Link to="/contact">Contact</Link>
					</li>
				</ul>
				<div className="hamburger" onClick={handleNav}>
					{!nav ? <FaBars className="icon" /> : <FaTimes className="icon" />}
				</div>
			</div>
		</div>
	);
}

export default NavBar;
