import React from "react";
import "./CompoundingStyles.css";

function CompoundingComponent() {
	return (
		<div className="compound" name="compound">
			<div className="content">
				<h2>
					<span>Compounding Department </span>
				</h2>
				<p>
					At Levin’s Drugs, we combine innovative research and the practice of
					compounding to provide custom-tailored medicine designed with you and
					your patients in mind. We value communication, courtesy, enthusiasm,
					and teamwork. We strive to provide utmost healthcare services to all
					patients and providers in a respectful manner through quality,
					efficient, patient-centered care.
				</p>
			</div>
		</div>
	);
}

export default CompoundingComponent;
