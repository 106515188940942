import React from "react";
import "./PolicyStyles.css";

function PolicyComponent() {
	return (
		<div className="policy">
			<div className="container">
				<h1>
					<span>Privacy </span>Policy
				</h1>
				<div className="content">
					<p>
						<strong>Our Privacy Policy was last updated on 02/11/2022</strong>
					</p>
					<p>
						This Privacy Policy describes Our policies and procedures on the
						collection, use and disclosure of Your information when You use the
						Service and tells You about Your privacy rights and how the law
						protects You.
					</p>
					<p>
						<strong>"Usage Data"</strong> refers to data collected
						automatically, either generated by the use of the Service or from
						the Service infrastructure itself (for example, the duration of a
						page visit).
					</p>

					<p>
						<strong>"Website"</strong> refers to LEVINSDERUGS, accessible from
						LEVINSDERUGS.COM
					</p>

					<p>
						<strong>"You"</strong> means the individual accessing or using the
						Service, or the company, or other legal entity on behalf of which
						such individual is accessing or using the Service, as applicable.
					</p>
					<p>
						Under GDPR (General Data Protection Regulation), You can be referred
						to as the Data Subject or as the User as you are the individual
						using the Service.
					</p>

					<p>
						<strong>Collecting and Using Your Personal Data</strong>
					</p>
					<p>
						<strong>Types of Data Collected</strong>
					</p>
					<p>
						<strong>Personal Data</strong>
					</p>
					<p>
						While using Our Service, We may ask You to provide Us with certain
						personally identifiable information that can be used to contact or
						identify You. Personally identifiable information may include, but
						is not limited to:
					</p>
					<ul>
						<li>
							<p>Email address</p>
						</li>
						<li>
							<p>First name and last name</p>
						</li>
						<li>
							<p>Phone number</p>
						</li>
						<li>
							<p>Address, State, Province, ZIP/Postal code, City</p>
						</li>
						<li>
							<p>Usage Data</p>
						</li>
					</ul>
					<p>
						<strong>Usage Data</strong>
					</p>
					<p>Usage Data is collected automatically when using the Service.</p>
					<p>
						Usage Data may include information such as Your Device's Internet
						Protocol address (e.g. IP address), browser type, browser version,
						the pages of our Service that You visit, the time and date of Your
						visit, the time spent on those pages, unique device identifiers and
						other diagnostic data.
					</p>
					<p>
						When You access the Service by or through a mobile device, We may
						collect certain information automatically, including, but not
						limited to, the type of mobile device You use, Your mobile device
						unique ID, the IP address of Your mobile device, Your mobile
						operating system, the type of mobile Internet browser You use,
						unique device identifiers and other diagnostic data.
					</p>
					<p>
						We may also collect information that Your browser sends whenever You
						visit our Service or when You access the Service by or through a
						mobile device.
					</p>
					<p>
						<strong>Tracking Technologies and Cookies</strong>
					</p>
					<p>
						We use Cookies and similar tracking technologies to track the
						activity on Our Service and store certain information. Tracking
						technologies used are beacons, tags, and scripts to collect and
						track information and to improve and analyze Our Service. The
						technologies We use may include:
					</p>
					<ul>
						<li>
							<strong>Cookies or Browser Cookies.</strong> A cookie is a small
							file placed on Your Device. You can instruct Your browser to
							refuse all Cookies or to indicate when a Cookie is being sent.
							However, if You do not accept Cookies, You may not be able to use
							some parts of our Service. Unless you have adjusted Your browser
							setting so that it will refuse Cookies, our Service may use
							Cookies.
						</li>
						<li>
							<strong>Web Beacons.</strong> Certain sections of our Service and
							our emails may contain small electronic files known as web beacons
							(also referred to as clear gifs, pixel tags, and single-pixel
							gifs) that permit the Company, for example, to count users who
							have visited those pages or opened an email and for other related
							website statistics (for example, recording the popularity of a
							certain section and verifying system and server integrity).
						</li>
					</ul>
					<p>
						Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
						remain on Your personal computer or mobile device when You go
						offline, while Session Cookies are deleted as soon as You close Your
						web browser.
					</p>
					<p>We use both Session Cookies for the purposes set out below:</p>
					<ul>
						<li>
							<p>
								<strong>Necessary / Essential Cookies</strong>
							</p>
							<p>Type: Session Cookies</p>
							<p>Administered by: Us</p>
							<p>
								Purpose: These Cookies are essential to provide You with
								services available through the Website and to enable You to use
								some of its features. They help to authenticate users and
								prevent fraudulent use of user accounts. Without these Cookies,
								the services that You have asked for cannot be provided, and We
								only use these Cookies to provide You with those services.
							</p>
						</li>
						<li>
							<p>
								<strong>Cookies Policy / Notice Acceptance Cookies</strong>
							</p>
							<p>Type: Persistent Cookies</p>
							<p>Administered by: Us</p>
							<p>
								Purpose: These Cookies identify if users have accepted the use
								of cookies on the Website.
							</p>
						</li>
						<li>
							<p>
								<strong>Functionality Cookies</strong>
							</p>
							<p>Type: Persistent Cookies</p>
							<p>Administered by: Us</p>
							<p>
								Purpose: These Cookies allow us to remember choices You make
								when You use the Website, such as remembering your login details
								or language preference. The purpose of these Cookies is to
								provide You with a more personal experience and to avoid You
								having to re-enter your preferences every time You use the
								Website.
							</p>
						</li>
						<li>
							<p>
								<strong>Tracking and Performance Cookies</strong>
							</p>
							<p>Type: Persistent Cookies</p>
							<p>Administered by: Third-Parties</p>
							<p>
								Purpose: These Cookies are used to track information about
								traffic to the Website and how users use the Website. The
								information gathered via these Cookies may directly or
								indirectly identify you as an individual visitor. This is
								because the information collected is typically linked to a
								pseudonymous identifier associated with the device you use to
								access the Website. We may also use these Cookies to test new
								pages, features or new functionality of the Website to see how
								our users react to them.
							</p>
						</li>
					</ul>
					<p>
						For more information about the cookies we use and your choices
						regarding cookies, please visit our Cookies Policy or the Cookies
						section of our Privacy Policy.
					</p>
					<p>
						<strong>Use of Your Personal Data</strong>
					</p>
					<p>The Company may use Personal Data for the following purposes:</p>

					<ul>
						<li>
							<p>
								<strong>To provide and maintain our Service</strong>, including
								to monitor the usage of our Service.
							</p>
						</li>
						<li>
							<p>
								<strong>To manage Your Account:</strong> to manage Your
								registration as a user of the Service. The Personal Data You
								provide can give You access to different functionalities of the
								Service that are available to You as a registered user.
							</p>
						</li>
						<li>
							<p>
								<strong>For the performance of a contract:</strong> the
								development, compliance and undertaking of the purchase contract
								for the products, items or services You have purchased or of any
								other contract with Us through the Service.
							</p>
						</li>
						<li>
							<p>
								<strong>To contact You:</strong> To contact You by email,
								telephone calls, SMS, or other equivalent forms of electronic
								communication, such as a mobile application's push notifications
								regarding updates or informative communications related to the
								functionalities, products or contracted services, including the
								security updates, when necessary or reasonable for their
								implementation.
							</p>
						</li>
						<li>
							<p>
								<strong>To provide You</strong> with news, special offers and
								general information about other goods, services and events which
								we offer that are similar to those that you have already
								purchased or enquired about unless You have opted not to receive
								such information.
							</p>
						</li>
						<li>
							<p>
								<strong>To manage Your requests:</strong> To attend and manage
								Your requests to Us.
							</p>
						</li>
					</ul>
					<p>
						<strong>Retention of Your Personal Data</strong>
					</p>
					<p>
						The Company will retain Your Personal Data only for as long as is
						necessary for the purposes set out in this Privacy Policy. We will
						retain and use Your Personal Data to the extent necessary to comply
						with our legal obligations (for example, if we are required to
						retain your data to comply with applicable laws), resolve disputes,
						and enforce our legal agreements and policies.
					</p>
					<p>
						The Company will also retain Usage Data for internal analysis
						purposes. Usage Data is generally retained for a shorter period of
						time, except when this data is used to strengthen the security or to
						improve the functionality of Our Service, or We are legally
						obligated to retain this data for longer time periods.
					</p>
					<p>
						<strong>Transfer of Your Personal Data</strong>
					</p>
					<p>
						Your information, including Personal Data, is processed at the
						Company's operating offices and in any other places where the
						parties involved in the processing are located. It means that this
						information may be transferred to — and maintained on — computers
						located outside of Your state, province, country or other
						governmental jurisdiction where the data protection laws may differ
						than those from Your jurisdiction.
					</p>
					<p>
						Your consent to this Privacy Policy followed by Your submission of
						such information represents Your agreement to that transfer.
					</p>
					<p>
						The Company will take all steps reasonably necessary to ensure that
						Your data is treated securely and in accordance with this Privacy
						Policy and no transfer of Your Personal Data will take place to an
						organization or a country unless there are adequate controls in
						place including the security of Your data and other personal
						information.
					</p>
					<p>
						<strong>Disclosure of Your Personal Data</strong>
					</p>
					<p>
						<strong>Business Transactions</strong>
					</p>
					<p>
						If the Company is involved in a merger, acquisition or asset sale,
						Your Personal Data may be transferred. We will provide notice before
						Your Personal Data is transferred and becomes subject to a different
						Privacy Policy.
					</p>
					<p>
						<strong>Law enforcement</strong>
					</p>
					<p>
						Under certain circumstances, the Company may be required to disclose
						Your Personal Data if required to do so by law or in response to
						valid requests by public authorities (e.g. a court or a government
						agency).
					</p>
					<p>
						<strong>Other legal requirements</strong>
					</p>
					<p>
						The Company may disclose Your Personal Data in the good faith belief
						that such action is necessary to:
					</p>
					<ul>
						<li>Comply with a legal obligation</li>
						<li>Protect and defend the rights or property of the Company</li>
						<li>
							Prevent or investigate possible wrongdoing in connection with the
							Service
						</li>
						<li>
							Protect the personal safety of Users of the Service or the public
						</li>
						<li>Protect against legal liability</li>
					</ul>
					<p>
						<strong>Security of Your Personal Data</strong>
					</p>
					<p>
						The security of Your Personal Data is important to Us, but remember
						that no method of transmission over the Internet, or method of
						electronic storage is 100% secure. While We strive to use
						commercially acceptable means to protect Your Personal Data, We
						cannot guarantee its absolute security.
					</p>
					<p>
						<strong>
							Detailed Information on the Processing of Your Personal Data
						</strong>
					</p>
					<p>
						The Service Providers We use may have access to Your Personal Data.
						These third-party vendors collect, store, use, process and transfer
						information about Your activity on Our Service in accordance with
						their Privacy Policies.
					</p>
					<p>
						<strong>Analytics</strong>
					</p>
					<p>
						We may use third-party Service providers to monitor and analyze the
						use of our Service.
					</p>

					<p>
						<strong>Payments</strong>
					</p>
					<p>
						We may provide paid products and/or services within the Service. In
						that case, we may use third-party services for payment processing
						(e.g. payment processors).
					</p>
					<p>
						We will not store or collect Your payment card details. That
						information is provided directly to Our third-party payment
						processors whose use of Your personal information is governed by
						their Privacy Policy. These payment processors adhere to the
						standards set by PCI-DSS as managed by the PCI Security Standards
						Council, which is a joint effort of brands like Visa, Mastercard,
						American Express and Discover. PCI-DSS requirements help ensure the
						secure handling of payment information.
					</p>

					<p>
						<strong>GDPR Privacy</strong>
					</p>
					<p>
						<strong>Legal Basis for Processing Personal Data under GDPR</strong>
					</p>
					<p>We may process Personal Data under the following conditions:</p>
					<ul>
						<li>
							<strong>Consent:</strong> You have given Your consent for
							processing Personal Data for one or more specific purposes.
						</li>
						<li>
							<strong>Performance of a contract:</strong> Provision of Personal
							Data is necessary for the performance of an agreement with You
							and/or for any pre-contractual obligations thereof.
						</li>
						<li>
							<strong>Legal obligations:</strong> Processing Personal Data is
							necessary for compliance with a legal obligation to which the
							Company is subject.
						</li>
						<li>
							<strong>Vital interests:</strong> Processing Personal Data is
							necessary in order to protect Your vital interests or of another
							natural person.
						</li>
						<li>
							<strong>Public interests:</strong> Processing Personal Data is
							related to a task that is carried out in the public interest or in
							the exercise of official authority vested in the Company.
						</li>
						<li>
							<strong>Legitimate interests:</strong> Processing Personal Data is
							necessary for the purposes of the legitimate interests pursued by
							the Company.
						</li>
					</ul>
					<p>
						In any case, the Company will gladly help to clarify the specific
						legal basis that applies to the processing, and in particular
						whether the provision of Personal Data is a statutory or contractual
						requirement, or a requirement necessary to enter into a contract.
					</p>

					<ul>
						<li>
							<strong>Request access to Your Personal Data.</strong> The right
							to access, update or delete the information We have on You.
							Whenever made possible, you can access, update or request deletion
							of Your Personal Data directly within Your account settings
							section. If you are unable to perform these actions yourself,
							please contact Us to assist You. This also enables You to receive
							a copy of the Personal Data We hold about You.
						</li>
						<li>
							<strong>
								Request correction of the Personal Data that We hold about You.
							</strong>{" "}
							You have the right to have any incomplete or inaccurate
							information We hold about You corrected.
						</li>
						<li>
							<strong>Object to processing of Your Personal Data.</strong> This
							right exists where We are relying on a legitimate interest as the
							legal basis for Our processing and there is something about Your
							particular situation, which makes You want to object to our
							processing of Your Personal Data on this ground. You also have the
							right to object where We are processing Your Personal Data for
							direct marketing purposes.
						</li>
						<li>
							<strong>Request erasure of Your Personal Data.</strong> You have
							the right to ask Us to delete or remove Personal Data when there
							is no good reason for Us to continue processing it.
						</li>
						<li>
							<strong>Request the transfer of Your Personal Data.</strong> We
							will provide to You, or to a third-party You have chosen, Your
							Personal Data in a structured, commonly used, machine-readable
							format. Please note that this right only applies to automated
							information which You initially provided consent for Us to use or
							where We used the information to perform a contract with You.
						</li>
						<li>
							<strong>Withdraw Your consent.</strong> You have the right to
							withdraw Your consent on using your Personal Data. If You withdraw
							Your consent, We may not be able to provide You with access to
							certain specific functionalities of the Service.
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
}

export default PolicyComponent;
