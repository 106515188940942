import React from "react";
import "./TermsStyles.css";
import { Link } from "react-router-dom";

function TermsComponent() {
	return (
		<div className="terms">
			<div className="container">
				<h1>
					<span>Terms and</span> Condtions
				</h1>
				<div className="text">
					<p>
						These terms and conditions ("Agreement") set forth the general terms
						and conditions of your use of the levinsdrugs.com website ("Website"
						or "Service") and any of its related products and services
						(collectively, "Services"). This Agreement is legally binding
						between you ("User", "you" or "your") and this Website operator
						("Operator", "we", "us" or "our"). By accessing and using the
						Website and Services, you acknowledge that you have read,
						understood, and agree to be bound by the terms of this Agreement. If
						you are entering into this Agreement on behalf of a business or
						other legal entity, you represent that you have the authority to
						bind such entity to this Agreement, in which case the terms "User",
						"you" or "your" shall refer to such entity. If you do not have such
						authority, or if you do not agree with the terms of this Agreement,
						you must not accept this Agreement and may not access and use the
						Website and Services. You acknowledge that this Agreement is a
						contract between you and the Operator, even though it is electronic
						and is not physically signed by you, and it governs your use of the
						Website and Services.
					</p>
					<h2>
						<span>Links to other resources</span>
					</h2>
					<p>
						Although the Website and Services may link to other resources (such
						as websites, mobile applications, etc.), we are not, directly or
						indirectly, implying any approval, association, sponsorship,
						endorsement, or affiliation with any linked resource, unless
						specifically stated herein. We are not responsible for examining or
						evaluating, and we do not warrant the offerings of, any businesses
						or individuals or the content of their resources. We do not assume
						any responsibility or liability for the actions, products, services,
						and content of any other third parties. You should carefully review
						the legal statements and other conditions of use of any resource
						which you access through a link on the Website and Services. Your
						linking to any other off-site resources is at your own risk.
					</p>
					<h2>
						<span>Changes and amendments</span>
					</h2>
					<p>
						We reserve the right to modify this Agreement or its terms relating
						to the Website and Services at any time, effective upon posting of
						an updated version of this Agreement on the Website. When we do, we
						will revise the updated date at the bottom of this page. Continued
						use of the Website and Services after any such changes shall
						constitute your consent to such changes.
					</p>
					<h2>
						<span>Acceptance of these terms</span>
					</h2>
					<p>
						You acknowledge that you have read this Agreement and agree to all
						its terms and conditions. By accessing and using the Website and
						Services you agree to be bound by this Agreement. If you do not
						agree to abide by the terms of this Agreement, you are not
						authorized to access or use the Website and Services.
					</p>
					<h2>
						<span>Contacting us</span>
					</h2>
					<p>
						If you would like to contact us to understand more about this
						Agreement or wish to contact us concerning any matter relating to
						it, please click this{" "}
						<Link to="/contact" className="blue">
							link
						</Link>{" "}
						or call (718) 459-1500
					</p>
				</div>
			</div>
		</div>
	);
}

export default TermsComponent;
