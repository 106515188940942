import React from "react";
import NavBar from "../components/navbar/NavBar";
import ContactComponent from "../components/contact/ContactComponent";

import FooterComponent from "../components/footer/FooterComponent";

function ContactPage() {
	return (
		<>
			<NavBar />
			<ContactComponent />
			<FooterComponent />
		</>
	);
}

export default ContactPage;
