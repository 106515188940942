import React, { useState } from "react";
import { Link } from "react-scroll";
import { Link as RouteLink } from "react-router-dom";
import Logo from "../../assets/Logo.ico";
import { BsFillArrowUpCircleFill } from "react-icons/bs";
import { FiMail } from "react-icons/fi";
import "./FooterStyles.css";

function FooterComponent() {
	const [mail, setMail] = useState("");

	const Mailto = () => {
		window.open(
			"mailto:Levins Drugs<levinsdrugs9890@yahoo.com?subject=Help Wanted&>"
		);
	};

	return (
		<div className="footer">
			<div className="container">
				<div className="top">
					<div className="logo-footer">
						<img src={Logo} alt="Logo" className="icon" />
						<h2>Levins Drugs</h2>
					</div>
					<Link
						activeClass="active"
						to="top"
						spy={true}
						smooth={true}
						duration={500}
					>
						<BsFillArrowUpCircleFill className="icon" />
					</Link>
				</div>

				<div className="col-container">
					<div className="col">
						<h3>Navigation</h3>
						<p>
							<RouteLink to="/">Home</RouteLink>
						</p>
						<p>
							<RouteLink to="/retail">Retail</RouteLink>
						</p>
						<p>
							<RouteLink to="/vaccine">Vaccinations </RouteLink>
						</p>
						<p>
							<RouteLink to="/compound">Compounding </RouteLink>
						</p>
					</div>
					<div className="col">
						<h3>Information</h3>
						<p>
							<RouteLink to="/location">Our Location</RouteLink>
						</p>

						<p>
							<RouteLink to="/contact">Contact </RouteLink>
						</p>
					</div>
					<div className="col">
						<h3>Leagal</h3>
						<p>
							<RouteLink to="/policy">Policies </RouteLink>
						</p>
						<p>
							<RouteLink to="/terms"> Terms & Conditions </RouteLink>
						</p>
					</div>
					<form>
						<h3>Join our team </h3>
						<input
							type="email"
							id="email"
							value={mail}
							onChange={(e) => setMail(e.target.value)}
							placeholder="Enter your email"
						/>
						<FiMail className="mail-icon" onClick={Mailto} />
					</form>
				</div>
				<div className="copyright">
					<p>
						{"Copyright © "}
						<RouteLink to="/">Levins Drugs, Inc</RouteLink>{" "}
						{new Date().getFullYear()}
						{"."}
					</p>
				</div>
			</div>
		</div>
	);
}

export default FooterComponent;
