import React from "react";
import NavBar from "../components/navbar/NavBar";
import TermsComponent from "../components/terms/TermsComponent";

import FooterComponent from "../components/footer/FooterComponent";

export default function TermsPage() {
	return (
		<>
			<NavBar />
			<TermsComponent />
			<FooterComponent />
		</>
	);
}
