import React from "react";
import NavBar from "../components/navbar/NavBar";
import VaccineComponent from "../components/vaccine/VaccineComponent";
import FooterComponent from "../components/footer/FooterComponent";

export default function VaccinePage() {
	return (
		<>
			<NavBar />
			<VaccineComponent />
			<FooterComponent />
		</>
	);
}
