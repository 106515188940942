import React, { useState } from "react";
import "./ContactStyles.css";

function ContactComponent() {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [msg, setMsg] = useState("");

	const handleSubmit = (e) => {
		e.preventDefault();
		const body = `Name: ${name} email: ${email} msg: ${msg}`;

		window.open(
			`mailto:Levins Drugs<levinsdrugs9890@yahoo.com?subject=Request from the web&body=${body}>`
		);
		setName("");
		setEmail("");
		setMsg("");
	};

	return (
		<div className="contact">
			<div className="container">
				<div className="form-container">
					<form onSubmit={handleSubmit}>
						<h1>
							<span>Contact</span> Us
						</h1>
						<div className="form-control">
							<label>Name</label>
							<input
								type="text"
								id="name"
								value={name}
								onChange={(e) => setName(e.target.value)}
								tabIndex="1"
								required
								placeholder="Enter your name"
							/>
						</div>
						<div className="form-control">
							<label>Email</label>
							<input
								type="text"
								tabIndex="2"
								id="email"
								required
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								placeholder="Enter your email"
							/>
						</div>
						<div className="form-control">
							<label>Message</label>
							<textarea
								cols="50"
								rows="10"
								id="msg"
								value={msg}
								required
								onChange={(e) => setMsg(e.target.value)}
								tabIndex="3"
								placeholder="Enter your message"
							></textarea>
						</div>
						<button type="submit">Submit</button>
					</form>
				</div>
			</div>
		</div>
	);
}

export default ContactComponent;
