import React from "react";
import "./AddressStyles.css";

function AddressComponent() {
	return (
		<div className="address">
			<div className="container">
				<h1>
					<span>Our</span>Address
				</h1>
				<div className="content">
					<p>9890A Queens Boulevard</p>
					<p>Rego Park, NY 11374</p>
					<p>tel: (718) 459-1500 fax: (718) 459-5956</p>
					<p>email: LEVINSDRUGS9890@YAHOO.COM</p>
				</div>
			</div>
		</div>
	);
}

export default AddressComponent;
