import React from "react";
import NavBar from "../components/navbar/NavBar";
import PolicyComponent from "../components/policy/PolicyComponent";
import FooterComponent from "../components/footer/FooterComponent";
import "../components/policy/PolicyStyles.css";
export default function PolicyPage() {
	return (
		<>
			<NavBar />
			<PolicyComponent />
			<FooterComponent />
		</>
	);
}
