import React from "react";
import "./VaccineStyles.css";

function VaccineComponent() {
	return (
		<div className="data">
			<div className="container">
				<div className="content">
					<h2>
						<span>COVID-19 and Flu (Influenza)</span> Vaccination
					</h2>
					<p>
						Vaccinations help protect you and the people around you from getting
						sick. Levins Drugs offers vaccinations at our Queens locations in
						Rego Park. As the flu and COVID-19 are expected to circulate at the
						same time this season, getting a flu vaccine is more important than
						ever. Although the flu vaccine will not prevent COVID-19, it will
						help decrease the risk of you and your family getting sick and
						needing flu-related medical care. You can receive the flu vaccine at
						the same time as all other vaccines, including the COVID-19 vaccine.
						For high-quality vaccination services, you can call our pharmacy at
						(718) 459-1500. Walk-in appointments are also available.
					</p>
				</div>
			</div>
		</div>
	);
}

export default VaccineComponent;
