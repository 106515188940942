import React from "react";
import NavBar from "../components/navbar/NavBar";
import AddressComponent from "../components/address/AddressComponent";

import FooterComponent from "../components/footer/FooterComponent";

export default function StoreLocationPage() {
	return (
		<>
			<NavBar />
			<AddressComponent />
			<FooterComponent />
		</>
	);
}
